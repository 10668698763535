<script>

import AppHeader from '../components/app-header'
import AppMenu from '../components/app-menu'

export default {

    components: {AppHeader, AppMenu},

    data() {
        return {
            title: '',
        }
    },

    watch: {

    },

    computed: {
    },

    async mounted() {
        
    },

    methods: {

    }
}
</script>

<template>
    <div>
        <AppHeader/>
        <AppMenu/>

        <div class="app-content content">
            <div class="content-wrapper">
              <div class="content-header row">
              </div>
              <div class="content-body"><!-- ICO Token &  Distribution-->
      <div class="row match-height">
          <div class="col-xl-8 col-12">
              <div class="card card-transparent">
                  <div class="card-header card-header-transparent py-20">
                      <div class="btn-group dropdown">
                          <h6>Динамика продажи токенов</h6>
                      </div>
                  </div>
                  <div id="ico-token-supply-demand-chart" class="height-300"></div>
              </div>
          </div>
          <div class="col-xl-4 col-lg-12">
              <div class="card card-transparent">
                  <div class="card-header card-header-transparent">
                      <h6 class="card-title">Распределение токенов</h6>
                  </div>
                  <div class="card-content">
                      <div id="token-distribution-chart" class="height-200 donut donutShadow"></div>
                      <div class="card-body">
                          <div class="row mx-0">
                              <ul class="token-distribution-list col-md-6 mb-0">
                                  <li class="crowd-sale">Массовая распродажа <span class="float-right text-muted">41%</span></li>
                                  <li class="team">Команда <span class="float-right text-muted">18%</span></li>                                  
                              </ul>
                              <ul class="token-distribution-list col-md-6 mb-0">
                                  <li class="advisors">Advisors <span class="float-right text-muted">15%</span></li>
                                  <li class="project-reserve">Проект <span class="float-right text-muted">10%</span></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--/ ICO Token &  Distribution-->
      <!-- Purchase token -->
      <div class="row">
          <div class="col-12">
              <div class="card pull-up">
                  <div class="card-content collapse show">
                      <div class="card-body">
                          <form class="form-horizontal form-purchase-token row" action="/buy">
                              <div class="col-md-2 col-12">
                                  <fieldset class="form-label-group mb-0">
                                      <input type="text" class="form-control" id="ico-token" value="5000" required="" autofocus="">
                                      <label for="ico-token">SIC Tokens</label>
                                  </fieldset>
                              </div>
                              <div class="col-md-1 col-12 text-center">
                                  <span class="la la-arrow-right"></span>
                              </div>
                              <div class="col-md-2 col-12">
                                  <fieldset class="form-label-group mb-0">
                                      <input type="text" class="form-control" id="selected-crypto" value="1" required="" autofocus="">
                                      <label for="selected-crypto">ETH</label>
                                  </fieldset>
                              </div>
                              <div class="col-md-1 col-12">
                                  <select class="custom-select">
                                      <option selected>ETH</option>
                                      <option value="1">BTC</option>
                                      <option value="2">LTC</option>
                                      <option value="3">USDT</option>
                                      <option value="3">Credit Card</option>
                                  </select>
                              </div>
                              <div class="col-md-4 col-12 mb-1">
                                  <fieldset class="form-label-group mb-0">
                                      <input type="text" class="form-control" id="wallet-address" value="0xABcDEFgHIJkLMNOpQRSTUvWXyZ" required="" autofocus="">
                                      <label for="wallet-address">Адрес кошелька</label>
                                  </fieldset>
                              </div>
                              <div class="col-md-2 col-12 text-center">
                                  <button type="submit" class="btn-gradient-secondary">Купить</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--/ Purchase token -->
      <!-- ICO Token balance & sale progress -->
      <div class="row">
          <div class="col-md-8 col-12">
              <h6 class="my-2">SIC баланс</h6>
              <div class="card pull-up">
                  <div class="card-content">
                      <div class="card-body">
                          <div class="col-12">
                              <div class="row">
                                  <div class="col-md-8 col-12">
                                      <p><strong>Ваш баланс:</strong></p>
                                      <h1>3,458.88 SIC</h1>
                                      <p class="mb-0">Приветственный бонус <strong>+10%</strong> истекает через 7 дней.</p>
                                  </div>
                                  <div class="col-md-4 col-12 text-center text-md-right">
                                      <button type="button" class="btn-gradient-secondary mt-2">Вывести <i class="la la-angle-right"></i></button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-4 col-12">
              <h6 class="my-2">ХОД ПРОДАЖИ ТОКЕНОВ</h6>
              <div class="card">
                  <div class="card-content collapse show">
                      <div class="card-body">
                          <div class="font-small-3 clearfix">
                              <span class="float-left">$0</span>
                              <span class="float-right">$5M</span>
                          </div>
                          <div class="progress progress-sm my-1 box-shadow-2">
                              <div class="progress-bar bg-warning" role="progressbar" style="width: 65%" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div class="font-small-3 clearfix">
                            <span class="float-left">Распределенный <br/> <strong>6,235,125 SIC</strong></span>
                            <span class="float-right text-right">Собрано  <br/> <strong>5478 ETH | 80 BTC</strong></span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--/ ICO Token balance & sale progress -->
      <!-- Recent Transactions -->
      <div class="row">
          <div id="recent-transactions" class="col-12">
              <h6 class="my-2">Последние транзакции</h6>
              <div class="card">
                  <div class="card-content">
                      <div class="table-responsive">
                          <table id="recent-orders" class="table table-hover table-xl mb-0">
                              <thead>
                                  <tr>
                                      <th class="border-top-0">Статус</th>
                                      <th class="border-top-0">Дата</th>
                                      <th class="border-top-0">Тип операции</th>
                                      <th class="border-top-0">Сумма</th>
                                      <th class="border-top-0">Валюта</th>
                                      <th class="border-top-0">Токены (SIC)</th>
                                      <th class="border-top-0">Подробности</th>
                                      <th class="border-top-0"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td class="text-truncate"><i class="la la-dot-circle-o success font-medium-1 mr-1"></i> Оплачено</td>
                                      <td class="text-truncate"><a href="#">2018-01-03</a></td>
                                      <td class="text-truncate">
                                          <a href="#" class="mb-0 btn-sm btn btn-outline-success round">Пополнение/a>
                                      </td>
                                      <td class="text-truncate p-1">5.34111</td>
                                      <td>
                                          <i class="cc ETH-alt"></i> ETH
                                      </td>
                                      <td>-</td>
                                      <td class="text-truncate">Пополнение баланса</td>
                                      <td><i class="la la-thumbs-up warning float-right"></i></td>
                                  </tr>
                                  <tr>
                                      <td class="text-truncate"><i class="la la-dot-circle-o success font-medium-1 mr-1"></i> Оплачено</td>
                                      <td class="text-truncate"><a href="#">2018-01-03</a></td>
                                      <td class="text-truncate">
                                          <a href="#" class="mb-0 btn-sm btn btn-outline-success round">Пополнение/a>
                                      </td>
                                      <td class="text-truncate p-1">5.34111</td>
                                      <td>
                                          <i class="cc ETH-alt"></i> ETH
                                      </td>
                                      <td>3,258</td>
                                      <td class="text-truncate">Покупка токенов</td>
                                      <td></td>
                                  </tr>
                                  <tr>
                                      <td class="text-truncate"><i class="la la-dot-circle-o warning font-medium-1 mr-1"></i> in Process</td>
                                      <td class="text-truncate"><a href="#">2018-01-21</a></td>
                                      <td class="text-truncate">
                                          <a href="#" class="mb-0 btn-sm btn btn-outline-warning round">Вознаграждение</a>
                                      </td>
                                      <td class="text-truncate p-1">-</td>
                                      <td>-</td>
                                      <td>200.88</td>
                                      <td class="text-truncate">Промо бонус</td>
                                      <td></td>
                                  </tr>
                                  <tr>
                                      <td class="text-truncate"><i class="la la-dot-circle-o danger font-medium-1 mr-1"></i> Ожидаемый</td>
                                      <td class="text-truncate"><a href="#">2018-01-25</a></td>
                                      <td class="text-truncate">
                                          <a href="#" class="mb-0 btn-sm btn btn-outline-danger round">Вывод</a>
                                      </td>
                                      <td class="text-truncate p-1">-</td>
                                      <td>-</td>
                                      <td>-3,458.88</td>
                                      <td class="text-truncate">Tokens withdrawn</td>
                                      <td><i class="la la-dollar warning float-right"></i></td>
                                  </tr>
                                  <tr>
                                      <td class="text-truncate"><i class="la la-dot-circle-o success font-medium-1 mr-1"></i> Оплачено</td>
                                      <td class="text-truncate"><a href="#">2018-01-28</a></td>
                                      <td class="text-truncate">
                                          <a href="#" class="mb-0 btn-sm btn btn-outline-danger round">Пополнение/a>
                                      </td>
                                      <td class="text-truncate p-1">0.8791</td>
                                      <td><i class="cc BTC-alt"></i> BTC</td>
                                      <td>--</td>
                                      <td class="text-truncate">Пополнение баланса</td>
                                      <td><i class="la la-thumbs-up warning float-right"></i></td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--/ Recent Transactions -->


              </div>
            </div>
          </div>

    </div>
</template>